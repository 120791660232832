import React from 'react';
//import { makeStyles, useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
// import logo from '../img/logo-cydocs-color.png';
import { Help } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import * as drawerDuck from '../ducks/Drawer';

const drawerWidth = 240;

const logo = 'https://storage.googleapis.com/mi-cyd/PRUEBA%20BPM%20GMI/Cydocs.svg';

const useStyles = makeStyles(theme => ({
    avatar: {
        width: 32,
        height: 32
    },
    avatarButton: {
        padding: 0
    },
    bigAvatar: {
        margin: 10
    },
    title_logo: {
        textAlign: 'left',
        paddingRight: '15px'
    },
    title_container: {
        flexGrow: 9,
        textAlign: 'left'
    },
    title: {
        margin: 0
    },
    sub_title: {
        margin: 0
    },
    root: {
        display: 'flex',
        padding: '0px',
        margin: '0px',
        width: 240
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    hide: {
        display: 'none'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end'
    },
    content: {
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        marginLeft: -drawerWidth
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0
    },
    logo: {
        height: '46px',
        verticalAlign: 'middle'
    },
    version: {
        position: 'fixed',
        bottom: 0,
        margin: '0 0 5px 10px'
    }
}));

export default function PersistentDrawerLeft(props) {
    const permisos = useSelector(state => state.PermisosUsuario);
    const perfiles = useSelector(state => state.Perfiles);
    const dispatch = useDispatch();
    const open = useSelector(state => state.Drawer);
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        open: false,
        open2: false,
        open3: false,
        form: {
            email: '',
            password: ''
        }
    });

    const handleClick = menu => e => {
        setState({
            ...state,
            open: menu === 'configuracion' ? !state.open : false,
            open1: menu === 'tablas' ? !state.open1 : false
        });
    };

    return (
        <Drawer style={{ width: 240 }} open={open.data} onClose={() => dispatch(drawerDuck.toggleDrawer())}>
            <div className={classes.root}>
                <List style={{ width: 240 }}>
                    <ListItem>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <img src={logo} alt="Logo" style={{ width: 160, height: 72 }} />
                        </Grid>
                    </ListItem>
                    {permisos.data.some(x => x.nombre === 'verDashboard') && (
                        <ListItem
                            button
                            component={Link}
                            to="/dashboard"
                            onClick={() => dispatch(drawerDuck.toggleDrawer())}>
                            {false && <ListItemIcon></ListItemIcon>}
                            <ListItemText primary="Dashboard" />
                        </ListItem>
                    )}
                    <ListItem
                        button
                        component={Link}
                        to="/nuevasolicitud"
                        onClick={() => dispatch(drawerDuck.toggleDrawer())}>
                        {false && <ListItemIcon></ListItemIcon>}
                        <ListItemText primary="Crear Nueva Solicitud" />
                    </ListItem>
                    <ListItem button component={Link} to="/" onClick={() => dispatch(drawerDuck.toggleDrawer())}>
                        {false && <ListItemIcon></ListItemIcon>}
                        <ListItemText primary="Ver Solicitudes" />
                    </ListItem>
                    {permisos.data.some(x => x.nombre === 'administrarProveedores') && (
                        <ListItem
                            button
                            component={Link}
                            to="/configuracion/proveedores"
                            onClick={() => dispatch(drawerDuck.toggleDrawer())}>
                            {false && <ListItemIcon></ListItemIcon>}
                            <ListItemText primary="Gestión Proveedores" />
                        </ListItem>
                    )}
                    {permisos.data.some(x => x.nombre === "verConfigProyectos") && (
                        <div>
                            <ListItem button onClick={handleClick('configuracion')}>
                                {false && <ListItemIcon></ListItemIcon>}
                                <ListItemText primary="Configuración" />
                                {state.open ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={state.open} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem
                                        button
                                        className={classes.nested}
                                        component={Link}
                                        to="/configuracion/proyectos"
                                        onClick={() => dispatch(drawerDuck.toggleDrawer())}>
                                        {false && <ListItemIcon></ListItemIcon>}
                                        <ListItemText inset secondary="Configuración de Proyectos" />
                                    </ListItem>
                                </List>
                            </Collapse>
                        </div>
                    )}
                    <div>
                        <ListItem button onClick={handleClick('tablas')}>
                            {false && <ListItemIcon></ListItemIcon>}
                            <ListItemText primary="Tablas Maestras" />
                            {state.open1 ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={state.open1} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {perfiles.data.some(x => x.nombre === 'perfilDesarrollador') && (
                                    <div>
                                        <ListItem
                                            button
                                            className={classes.nested}
                                            component={Link}
                                            to="/configuracion/clases"
                                            onClick={() => dispatch(drawerDuck.toggleDrawer())}>
                                            {false && <ListItemIcon></ListItemIcon>}
                                            <ListItemText inset secondary="Clases" />
                                        </ListItem>
                                        {/* <ListItem
                                            button
                                            className={classes.nested}
                                            component={Link}
                                            to="/configuracion/cilindrada"
                                            onClick={() => dispatch(drawerDuck.toggleDrawer())}>
                                            {false && <ListItemIcon></ListItemIcon>}
                                            <ListItemText inset secondary="Cilindrada" />
                                        </ListItem>
                                        <ListItem
                                            button
                                            className={classes.nested}
                                            component={Link}
                                            to="/configuracion/colores"
                                            onClick={() => dispatch(drawerDuck.toggleDrawer())}>
                                            {false && <ListItemIcon></ListItemIcon>}
                                            <ListItemText inset secondary="Colores" />
                                        </ListItem>
                                        <ListItem
                                            button
                                            className={classes.nested}
                                            component={Link}
                                            to="/configuracion/discosduros"
                                            onClick={() => dispatch(drawerDuck.toggleDrawer())}>
                                            {false && <ListItemIcon></ListItemIcon>}
                                            <ListItemText inset secondary="Discos Duros" />
                                        </ListItem>
                                        {false && (
                                            <ListItem
                                                button
                                                className={classes.nested}
                                                component={Link}
                                                to="/configuracion/empresas"
                                                onClick={() => dispatch(drawerDuck.toggleDrawer())}>
                                                {false && <ListItemIcon></ListItemIcon>}
                                                <ListItemText inset secondary="Empresas" />
                                            </ListItem>
                                        )}
                                        <ListItem
                                            button
                                            className={classes.nested}
                                            component={Link}
                                            to="/configuracion/marcas-vehiculos"
                                            onClick={() => dispatch(drawerDuck.toggleDrawer())}>
                                            {false && <ListItemIcon></ListItemIcon>}
                                            <ListItemText inset secondary="Marcas Vehículos" />
                                        </ListItem>
                                        <ListItem
                                            button
                                            className={classes.nested}
                                            component={Link}
                                            to="/configuracion/ram"
                                            onClick={() => dispatch(drawerDuck.toggleDrawer())}>
                                            {false && <ListItemIcon></ListItemIcon>}
                                            <ListItemText inset secondary="Memorias Ram" />
                                        </ListItem>
                                        <ListItem
                                            button
                                            className={classes.nested}
                                            component={Link}
                                            to="/configuracion/procesadores"
                                            onClick={() => dispatch(drawerDuck.toggleDrawer())}>
                                            {false && <ListItemIcon></ListItemIcon>}
                                            <ListItemText inset secondary="Procesadores" />
                                        </ListItem> */}
                                        <ListItem
                                            button
                                            className={classes.nested}
                                            component={Link}
                                            to="/configuracion/subclases"
                                            onClick={() => dispatch(drawerDuck.toggleDrawer())}>
                                            {false && <ListItemIcon></ListItemIcon>}
                                            <ListItemText inset secondary="Sub-Clases" />
                                        </ListItem>
                                        {/* <ListItem
                                            button
                                            className={classes.nested}
                                            component={Link}
                                            to="/configuracion/tipo-motor"
                                            onClick={() => dispatch(drawerDuck.toggleDrawer())}>
                                            {false && <ListItemIcon></ListItemIcon>}
                                            <ListItemText inset secondary="Tipo de Motor" />
                                        </ListItem>
                                        <ListItem
                                            button
                                            className={classes.nested}
                                            component={Link}
                                            to="/configuracion/traccion"
                                            onClick={() => dispatch(drawerDuck.toggleDrawer())}>
                                            {false && <ListItemIcon></ListItemIcon>}
                                            <ListItemText inset secondary="Tracciones" />
                                        </ListItem> */}
                                    </div>
                                )}
                                {/* {permisos.data.some(x => x.nombre === 'administrarProveedores') && (
                                    <ListItem
                                        button
                                        className={classes.nested}
                                        component={Link}
                                        to="/configuracion/proveedores"
                                        onClick={() => dispatch(drawerDuck.toggleDrawer())}>
                                        {false && <ListItemIcon></ListItemIcon>}
                                        <ListItemText inset secondary="Proveedores" />
                                    </ListItem>
                                )} */}
                            </List>
                        </Collapse>
                    </div>
                    {/* {false && (
                        <ListItem button>
                            {false && <ListItemIcon></ListItemIcon>}
                            <ListItemText primary="Contactos" />
                        </ListItem>
                    )}
                    <ListItem button component={Link} to="/ayuda" onClick={() => dispatch(drawerDuck.toggleDrawer())}>
                        <ListItemIcon>
                            <Help></Help>
                        </ListItemIcon>
                        <ListItemText primary="Ayuda" />
                    </ListItem> */}
                </List>
                <div className={classes.version}>v1.8.0</div>
            </div>
        </Drawer>
    );
}
