import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Typography, Grid, Box, Tooltip, Button, IconButton } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import Table from '../components/Table';
import Modal from '../components/Modal';
import FormEntregarItems from '../components/FormEntregarItems';
import FormListoParaEntrega from '../components/FormListoParaEntrega';
import FormItemTraslado from '../components/FormItemTraslado';
import BotonMenuItems from '../components/BotonMenuItems';
import * as solicitudesUsuarioDuck from '../ducks/SolicitudesUsuario';
import * as solicitudesAprobarDuck from '../ducks/SolicitudesAprobar';
import * as solicitudesDuck from '../ducks/Solicitudes';
import * as idsolicitudDuck from '../ducks/Idsolicitud';
import * as iditemDuck from '../ducks/Iditem';
import * as itemsDuck from '../ducks/Items';
import * as aprobador1Duck from '../ducks/Aprobador1';
import * as aprobador2Duck from '../ducks/Aprobador2';
import * as vehiculoDuck from '../ducks/Vehiculos';
import * as mensajesDuck from '../ducks/Mensajes';
import * as modoEdicionDuck from '../ducks/ModoEdicion';
import * as gruposCotizacionDuck from '../ducks/GruposCotizacion';
import * as cotizacionesDuck from '../ducks/Cotizaciones';
import * as idGrupoCotizacionDuck from '../ducks/IdGrupoCotizacion';
import * as itemsCotizacionDuck from '../ducks/ItemsCotizacion';
import * as archivoDuck from '../ducks/Archivo';
import * as itemsCotizadosDuck from '../ducks/ItemsCotizados';
import * as proyectosGeneralDuck from '../ducks/ProyectosGeneral';
import * as gerenciasGeneralDuck from '../ducks/GerenciasGeneral';
import * as comprobanteRecepcionDuck from '../ducks/ComprobanteRecepcion';
import * as trasladoItemRecursoDuck from '../ducks/TrasladoItemRecurso';
import * as entregaItemRecurso from '../ducks/EntregaItemRecurso';
import { uploadFileAsPromise, downloadUrlAsPromise } from '../services/utils';
import { saveAs } from 'file-saver';
import moment from 'moment';
import 'moment/locale/es';

class EntregaRecepcion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openModalRechazo: false,
            openModalSeparar: false,
            openModalEntregar: false,
            openCrearCotizacion: false,
            openVerCotizacion: false,
            openItemTraslado: false,
            openItemRecibido: false,
            openListoEntrega: false,
            confirmacion: false,
            accion: null,
            loading: false,
            mostrarFormulario: true,
            mostrarBusqueda: true,
            mostrarDetalle: false,
            loadingDetalle: true,
            loadingTraslado: false,
            loadingListoEntrega: false,
            loadingEntrega: false,
            comprobante: '',
            cantidadItemsEntregados: 0,
            textoItemsEntregados: '',
            maxItemsEntrega: 0,
            cantidadItemsRecibidos: 0,
            textoItemsRecibidos: '',
            maxItemsRecibidos: 0,
            textoItemsEnTraslado: '',
            maxItemsEnTraslado: 0,
            itemsSeleccionados: []
        };
    }

    //******** MODALES ************
    handleClose = () => {
        this.setState({
            ...this.state,
            openItemRecibido: false,
            openItemTraslado: false,
            openVerCotizacion: false,
            accion: false,
            confirmacion: false,
            open: false,
            openModalRechazo: false,
            openModalSeparar: false,
            openModalEntregar: false,
            openListoEntrega: false
        });
    };

    verificarEstadoSolicitud = async () => {
        const { idSolicitud, editarSolicitudes, solicitudActual } = this.props;
        if (solicitudActual.items.some(x => x.estado === 3)) {
            await editarSolicitudes(idSolicitud, { estado: 3 });
        } else if (solicitudActual.items.some(x => x.estado === 7)) {
            await editarSolicitudes(idSolicitud, { estado: 7 });
        } else if (solicitudActual.items.some(x => x.estado === 8 || x.estado === 22)) {
            await editarSolicitudes(idSolicitud, { estado: 8 });
        } else if (solicitudActual.items.some(x => x.estado === 9)) {
            await editarSolicitudes(idSolicitud, { estado: 9 });
        } else if (solicitudActual.items.some(x => x.estado === 12)) {
            await editarSolicitudes(idSolicitud, { estado: 12 });
        } else if (solicitudActual.items.some(x => x.estado === 6)) {
            await editarSolicitudes(idSolicitud, { estado: 6 });
        } else if (solicitudActual.items.some(x => x.estado === 17)) {
            await editarSolicitudes(idSolicitud, { estado: 17 });
        } else if (solicitudActual.items.some(x => x.estado === 18)) {
            await editarSolicitudes(idSolicitud, { estado: 18 });
        } else if (solicitudActual.items.some(x => x.estado === 20)) {
            await editarSolicitudes(idSolicitud, { estado: 20 });
        }
    };

    //Modal para edición de items
    // handleEditarItemsModal = (id, accion) => async e => {
    //     const {
    //         assignMensaje,
    //         fetchItems,
    //         consultarItem,
    //         itemsCotizacionActual,
    //         consultarItemCotizacion,
    //         assignIditem,
    //         solicitudActual,
    //         idSolicitud,
    //         consultarItemCompleto
    //     } = this.props;
    //     let cantidadAnterior = solicitudActual.items.filter(x => x.id === id)[0].cantidad;
    //     switch (accion) {
    //         case 6:
    //             let itemActual = await consultarItem(id);
    //             if ((itemActual.estado === 3 || itemActual.estado === 7) && itemActual.cantidad === cantidadAnterior) {
    //                 assignMensaje('¿Desea aplicar acción EXISTE?');
    //                 this.setState({
    //                     ...this.state,
    //                     confirmacion: true,
    //                     open: true,
    //                     accion: this.handleEditarItems(id, accion)
    //                 });
    //             } else if (itemActual.estado === 12 && itemActual.cantidad === cantidadAnterior) {
    //                 //assignMensaje('¿Desea aplicar acción RECIBIDO?')
    //                 //this.setState({ ...this.state, confirmacion: true, open: true, accion: this.handleEditarItems(id, accion) })
    //                 await assignIditem(id);
    //                 let itemCotizacion = itemsCotizacionActual.data.filter(x => x.item_recurso_id === id)[0];
    //                 let itemActualCotizacion = await consultarItemCotizacion(itemCotizacion.id);
    //                 var totalRecibidos = 0;
    //                 if (itemActualCotizacion.recepciones) {
    //                     for (var e = 0; e < itemActualCotizacion.recepciones.length; e++) {
    //                         totalRecibidos += itemActualCotizacion.recepciones[e].cantidad;
    //                     }
    //                 }
    //                 let recibidos = totalRecibidos + ' de ' + itemActualCotizacion.cantidad;
    //                 let maxRecibidos = itemActualCotizacion.cantidad - totalRecibidos;
    //                 this.setState({
    //                     ...this.state,
    //                     confirmacion: false,
    //                     openItemRecibido: true,
    //                     accion: false,
    //                     cantidadItemsRecibidos: totalRecibidos,
    //                     totalRecibir: itemActual.cantidad,
    //                     textoItemsRecibidos: recibidos,
    //                     maxItemsRecibidos: maxRecibidos
    //                 });
    //             } else {
    //                 await fetchItems(idSolicitud);
    //                 assignMensaje('Lista de Items actualizada');
    //                 this.setState({ ...this.state, confirmacion: false, open: true, accion: false });
    //             }
    //             break;
    //         case 7:
    //             assignMensaje('¿Desea aplicar acción SOLICITAR?');
    //             this.setState({
    //                 ...this.state,
    //                 confirmacion: true,
    //                 open: true,
    //                 accion: this.handleEditarItems(id, accion)
    //             });
    //             break;
    //         case 8:
    //             assignMensaje('¿Desea aplicar acción COMPRAR?');
    //             this.setState({
    //                 ...this.state,
    //                 confirmacion: true,
    //                 open: true,
    //                 accion: this.handleEditarItems(id, accion)
    //             });
    //             break;
    //         case 18: {
    //             // assignMensaje('¿Desea aplicar acción LISTO PARA ENTREGA?')
    //             let itemActual = await consultarItemCompleto(id);
    //             await assignIditem(id);
    //             let listos = itemActual.cantidad_listos_entrega + ' de ' + itemActual.cantidad;
    //             let maxListos = itemActual.cantidad - itemActual.cantidad_listos_entrega;
    //             this.setState({
    //                 ...this.state,
    //                 confirmacion: true,
    //                 openListoEntrega: true,
    //                 accion: accion,
    //                 textoItemsListoEntrega: listos,
    //                 maxItemsListoEntrega: maxListos
    //             });
    //             break;
    //         }
    //         case 17: {
    //             await assignIditem(id);
    //             let itemActual = await consultarItemCompleto(id);
    //             let max = itemActual.cantidad;
    //             let enTraslado = 0;
    //             if (itemActual.traslados) {
    //                 for (let i = 0; i < itemActual.traslados.length; i++) {
    //                     enTraslado += itemActual.traslados[i].cantidad;
    //                 }
    //             }
    //             if (itemActual.cotizaciones) {
    //                 max = 0;
    //                 for (let i = 0; i < itemActual.cotizaciones.length; i++) {
    //                     let recepciones = itemActual.cotizaciones[i].recepciones;
    //                     if (recepciones) {
    //                         for (let j = 0; j < recepciones.lenght; j++) {
    //                             max = recepciones[j].cantidad;
    //                         }
    //                     }
    //                 }
    //             }
    //             let textoEnTraslado = enTraslado + ' de ' + max;
    //             this.setState({
    //                 ...this.state,
    //                 confirmacion: false,
    //                 openItemTraslado: true,
    //                 accion: false,
    //                 textoItemsEnTraslado: textoEnTraslado,
    //                 maxItemsEnTraslado: max
    //             });
    //             break;
    //         }
    //         case 20: {
    //             // assignMensaje('¿Desea aplicar acción ENTREGADO?')
    //             // this.setState({...this.state, confirmacion: true, open: true, accion: this.handleItemEntregado(id) })
    //             assignMensaje('Cargando');
    //             let itemActual = await consultarItemCompleto(id);
    //             await assignIditem(id);
    //             var totalEntregados = 0;
    //             if (itemActual.entregas) {
    //                 for (var e = 0; e < itemActual.entregas.length; e++) {
    //                     totalEntregados += itemActual.entregas[e].cantidad;
    //                 }
    //             }
    //             let entregados = totalEntregados + ' de ' + itemActual.cantidad;
    //             let maxEntrega = itemActual.cantidad - totalEntregados;
    //             this.setState({
    //                 ...this.state,
    //                 confirmacion: false,
    //                 openModalEntregar: true,
    //                 accion: false,
    //                 cantidadItemsEntregados: totalEntregados,
    //                 totalEntregar: itemActual.cantidad,
    //                 textoItemsEntregados: entregados,
    //                 maxItemsEntrega: maxEntrega
    //             });
    //             break;
    //         }
    //         case 'separar': {
    //             this.setState({ ...this.state, open: true, confirmacion: false, accion: false }, async () => {
    //                 assignMensaje('Cargando');
    //                 let itemActual = await consultarItem(id);
    //                 if (itemActual.cantidad === cantidadAnterior) {
    //                     await assignIditem(id);
    //                     this.setState({ ...this.state, confirmacion: false, openModalSeparar: true, accion: false });
    //                 } else {
    //                     await fetchItems(idSolicitud);
    //                     assignMensaje('Lista de Items actualizada');
    //                     this.setState({ ...this.state, confirmacion: false, open: true, accion: false });
    //                 }
    //             });

    //             break;
    //         }
    //         default:
    //             break;
    //     }
    // };

    handleListoParaEntregar = async values => {
        const { recargarDatos, idSolicitud, assignMensaje, usuarioAutenticado, agregarItemListoEntrega } = this.props;
        this.setState({ loadingListoEntrega: true });
        let seleccionados = this.state.itemsSeleccionados.map(x => ({
            ...x,
            accion: null,
            item_recurso_equipamiento: null,
            responsables: null,
            tableData: null
        }));
        // console.log('listo entrega falso');
        
        const form = {
            id_solicitud: idSolicitud,
            items_listos_entrega: seleccionados,
            fecha: moment().toISOString(),
            usuario_id: usuarioAutenticado.data.id
        };
        await agregarItemListoEntrega(form);
        await recargarDatos(false, true);
        await this.verificarEstadoSolicitud();
        recargarDatos(false, true);
        assignMensaje('Recursos listos para entregar');
        this.setState({ confirmacion: false, open: true, accion: false, openListoEntrega: false, loadingListoEntrega: false, itemsSeleccionados: [] });
        
    };

    handleEntregarItems = async values => {
        const {
            recargarDatos,
            idSolicitud,
            assignMensaje,
            agregarComprobante,
            agregarEntregaItemRecurso,
            usuarioAutenticado
        } = this.props;
        this.setState({ loadingEntrega: true });
        // console.log('entrega falso', values, moment(values.fecha, "DD/MM/YYYY"));
        let seleccionados = this.state.itemsSeleccionados.map(x => ({
            ...x,
            accion: null,
            item_recurso_equipamiento: null,
            responsables: null,
            tableData: null
        }));
        let fecha = values.fecha.split('/');
        const formComprobante = {
            numero: !values.numero || values.numero == "" ? "1" : values.numero,
            fecha: fecha[2] + fecha[1] + fecha[0]
        };
        let id_comprobante = null;
        if (values.file) {
            let respuesta = await uploadFileAsPromise(values.file, "1Q8JrC41n9GwLe0S1l_4jaz8f9jNrnzSb");
            const comprobante = await agregarComprobante({
                ...formComprobante,
                file_id: respuesta.id,
                file_nombre: respuesta.nombre,
                file_url: respuesta.url
            });
            id_comprobante = comprobante.id;
        }

        const formEntrega = {
            documento_entrega_id: id_comprobante,
            id_solicitud: idSolicitud,
            items_entrega: seleccionados,
            lugar_entrega: values.lugar_entrega,
            nombre_recibe: values.nombre_recibe,
            nombre_entrega: values.nombre_entrega,
            fecha: moment(values.fecha, "DD/MM/YYYY").toISOString(),
            entregado_por_id: usuarioAutenticado.data.id
        };
        await agregarEntregaItemRecurso(formEntrega);
        await recargarDatos(false, true);
        await this.verificarEstadoSolicitud();
        recargarDatos(false, true);
        assignMensaje('Entrega realizada con éxito');
        this.setState({ openModalEntregar: false, loadingEntrega: false, confirmacion: false, open: true, accion: false, itemsSeleccionados: [] });
        
    };

    handleItemTraslado = async values => {
        const {
            idSolicitud,
            recargarDatos,
            assignMensaje,
            usuarioAutenticado,
            agregarTrasladoItemRecurso,
            verificarOrdenTransporte,
        } = this.props;
        this.setState({ loadingTraslado: true });
        let seleccionados = this.state.itemsSeleccionados.map(x => ({
            ...x,
            accion: null,
            item_recurso_equipamiento: null,
            responsables: null,
            tableData: null
        }));
        // console.log('traslado falso', this.state.itemsSeleccionados, values, idSolicitud, seleccionados);
        const form = {
            id_solicitud: idSolicitud,
            items_traslado: seleccionados,
            informacion_traslado: values.info_traslado,
            numero_seguimiento: values.numero_traslado,
            transportista: values.transportista,
            // item_recurso_id: idItem,
            // cantidad: values.cantidad,
            fecha: moment().toISOString(),
            // entregado_por_id: usuarioAutenticado.data.id,
            usuario_id: usuarioAutenticado.data.id
        };
        
        const ordenData = values.transportista === 'Chilexpress' ? await verificarOrdenTransporte(values.numero_traslado) : true;

        if (values.transportista === 'Chilexpress' && ordenData?.statusCode !== 0) {
            assignMensaje('Revisar número de seguimiento, ya que no fue reconocido por Chilexpress');
            this.setState({ loadingTraslado: false, open: true });
        } else {
            if (
                values.transportista === 'Chilexpress' &&
                ordenData?.data?.trackingEvents &&
                ordenData.data.trackingEvents.length > 0
            ) {
                // const ultimoEvento = ordenData.data.trackingEvents[3];
                const ultimoEvento = ordenData.data.trackingEvents.reduce((max, current) => {
                    const timestampMax = Date.parse(max.eventDateTime);
                    const timestampCurrent = Date.parse(current.eventDateTime);
                    return timestampCurrent > timestampMax ? current : max;
                }, ordenData.data.trackingEvents[0]);
                
                form.ultimoEvento = {
                    eventDateTime: ultimoEvento.eventDateTime,
                    latitud: ultimoEvento.latitude,
                    longitud: ultimoEvento.longitude,
                    descripcion: ultimoEvento.description
                };
            }
            await agregarTrasladoItemRecurso(form);
            await recargarDatos(false, true);
            await this.verificarEstadoSolicitud();
            recargarDatos(false, true);
            assignMensaje('Recursos en traslado');
            this.setState({ openItemTraslado: false, loadingTraslado: false, confirmacion: false, open: true, accion: false, itemsSeleccionados: [] });
        }
    };

    handleOnChangeFileComprobante = e => {
        this.setState({ ...this.state, comprobante: e.target.files[0] ? e.target.files[0].name : '' });
    };

    handleItemSelectionChange = rows => {
        // console.log('cambio en selection selected: ', rows.filter(x => x.seleccionable));
        let arr = [];
        rows.filter(x => x.seleccionable).forEach(dataFila => {
            arr.push(dataFila);
        });
        this.setState({ itemsSeleccionados: arr });
    };

    handleItemTrasladoModal = () => {
        // console.log('modal en traslado');
        this.setState({ confirmacion: false, openItemTraslado: true, accion: false });
    };

    handleItemListoParaEntregarModal = () => {
        // console.log('modal listo entregar');
        this.setState({
            confirmacion: true,
            openListoEntrega: true
            // accion: accion,
        });
    };

    handleItemEntregarModal = () => {
        // console.log('modal entregar');
        this.setState({
            confirmacion: false,
            openModalEntregar: true,
            accion: false
            // cantidadItemsEntregados: totalEntregados,
            // totalEntregar: itemActual.cantidad,
            // textoItemsEntregados: entregados,
            // maxItemsEntrega: maxEntrega
        });
    };

    renderMonitor = rowData => {
        if (rowData.estado_id) {
            let listadoNombres = '';
            if (rowData.responsables) {
                rowData.responsables.forEach(x => {
                    if (listadoNombres.length > 0) listadoNombres += '\n';
                    listadoNombres += x.nombres + ' ' + x.apellidos;
                });
            }
            if (rowData.estado_id == 22) listadoNombres += 'Ver Cotizaciones';
            if (listadoNombres.length == 0) {
                return '';
            } else {
                return (
                    <Tooltip enterTouchDelay={0} title={<div style={{ whiteSpace: 'pre', textAlign: 'left' }}>{listadoNombres}</div>}>
                        <PersonIcon />
                    </Tooltip>
                );
            }
        } else {
            return '';
        }
    };

    renderSeguimiento = rowData => {
        if (rowData.traslados?.length) {
            let dataTraslado = rowData.traslados[0];
            return (
                <div style={{ whiteSpace: 'pre', textAlign: 'left' }}>
                    Transportista:
                    {' '  + dataTraslado.transportista}
                    <br />
                    Nº:
                    {' ' + dataTraslado.numero_seguimiento}
                    <br />
                    {dataTraslado.ultimoEvento
                        ?   <div>
                                Estado:
                                {' ' + dataTraslado.ultimoEvento.descripcion}
                                <br />
                                Fecha:
                                {' '  + moment(dataTraslado.ultimoEvento.eventDateTime).format('DD-MM-YYYY HH:mm:ss')}
                                <br />
                                {dataTraslado.ultimoEvento.latitud && dataTraslado.ultimoEvento.longitud
                                    ?   <div>
                                            Ubicación:
                                            <IconButton
                                                color="primary"
                                                size="small"
                                                target='_blank'
                                                href={'https://www.google.com/maps?q=' + dataTraslado.ultimoEvento.latitud + ',' + dataTraslado.ultimoEvento.longitud}>
                                                <LocationOnIcon />
                                            </IconButton>
                                        </div>
                                    :   ''
                                }
                            </div>
                        :   ''
                    }
                </div>
            );
        } else {
            return '-';
        }
    };

    renderDetalleEntrega = rowData => {
        if (rowData.entregas?.length) {
            let dataEntrega = rowData.entregas[0];
            return (
                <div style={{ whiteSpace: 'pre', textAlign: 'left' }}>
                    Lugar:
                    {' ' + dataEntrega.lugar_entrega}
                    <br />
                    Fecha:
                    {' '  + moment(dataEntrega.fecha).format('DD-MM-YYYY')}
                    <br />
                    Entrega:
                    {' '  + dataEntrega.nombre_entrega}
                    <br />
                    Recibe:
                    {' '  + dataEntrega.nombre_recibe}
                    <br />
                    {dataEntrega?.documento_entrega ? (
                        <div>
                            Comprobante:
                            <Tooltip title="Ver Archivo">
                            <IconButton
                                color="primary"
                                size="small"
                                style={{ marginRight: 10 }}
                                onClick={() =>
                                this.verArchivoDocumento(dataEntrega.documento_entrega.archivo_id, 'comprobante')
                                }>
                                <InsertDriveFileOutlinedIcon />
                            </IconButton>
                            </Tooltip>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            );
        } else {
            return '-';
        }
    };

    verArchivoDocumento = (archivo_id, nombre) => {
        var request =
            'https://www.googleapis.com/drive/v3/files/' +
            archivo_id +
            '?alt=media';

        var newTab = window.open(window.location.href + '/waiting', '_blank');
        downloadUrlAsPromise(request, nombre)
            .then(function(response) {
                // console.log('tipo de archivo: ', response.blob.type);
                var tipoGrupo = response.blob.type ? response.blob.type.split('/')[0] : '';
                if (tipoGrupo == 'image' || response.blob.type == 'application/pdf') {
                    var blobUrl = URL.createObjectURL(response.blob);
                    newTab.location = blobUrl;
                } else {
                    newTab.close();
                    saveAs(response.blob, nombre);
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    render() {
        const {
            solicitudActual,
            revisarStock,
            mensaje,
            formTraslado,
        } = this.props;

        return (
            <Grid item xs={12}>
                <Box>
                    <Typography variant="h5" gutterBottom>
                        Solicitud de Recurso N° {solicitudActual ? solicitudActual.codigo : ''}
                    </Typography>
                </Box>
                {revisarStock &&
                    solicitudActual.items.some(
                        x =>
                            (x.estado === 6 || x.estado === 17 || x.estado === 18) && x.deMisCentros
                    ) && (
                        <Box>
                            <Grid>
                                <Button
                                    onClick={this.handleItemTrasladoModal}
                                    variant="outlined"
                                    color="primary"
                                    disabled={
                                        this.state.itemsSeleccionados.some(x => x.estado_id === 6) === false ||
                                        this.state.itemsSeleccionados.some(
                                            x => x.estado_id === 17 || x.estado_id === 18
                                        ) === true
                                    }>
                                    En Traslado
                                </Button>
                                <Button
                                    onClick={this.handleItemListoParaEntregarModal}
                                    variant="outlined"
                                    color="primary"
                                    disabled={
                                        this.state.itemsSeleccionados.some(
                                            x => x.estado_id === 6 || x.estado_id === 17
                                        ) === false ||
                                        this.state.itemsSeleccionados.some(x => x.estado_id === 18) === true
                                    }>
                                    Listo para entregar
                                </Button>
                                <Button
                                    onClick={this.handleItemEntregarModal}
                                    variant="outlined"
                                    color="primary"
                                    disabled={
                                        this.state.itemsSeleccionados.some(
                                            x => x.estado_id === 6 || x.estado_id === 17 || x.estado_id === 18
                                        ) === false
                                    }>
                                    Entregado
                                </Button>
                            </Grid>
                            <br />
                        </Box>
                    )}
                <Box>
                    <Table
                        search={false}
                        toolbar={false}
                        paging={false}
                        sorting={false}
                        selection={
                            revisarStock &&
                            solicitudActual.items.some(
                                x =>
                                    (x.estado === 6 || x.estado === 17 || x.estado === 18) && x.deMisCentros
                            )
                        }
                        handleSelectionChange={
                            revisarStock &&
                            solicitudActual.items.some(
                                x =>
                                    (x.estado === 6 || x.estado === 17 || x.estado === 18) && x.deMisCentros
                            )
                                ? this.handleItemSelectionChange
                                : null
                        }
                        columnas={[
                            {
                                title: 'Cantidad',
                                field: 'cantidad',
                                cellStyle: {
                                    width: '80px',
                                    textAlign: 'center',
                                    padding: '0px'
                                },
                                headerStyle: {
                                    textAlign: 'center',
                                    padding: '0px'
                                }
                            },
                            {
                                title: 'Estado',
                                field: 'estado',
                                cellStyle: {
                                    width: '100px',
                                    textAlign: 'center',
                                    padding: '0px'
                                },
                                headerStyle: {
                                    textAlign: 'center',
                                    padding: '0px'
                                }
                            },
                            {
                                title: '¿Quién Gestiona?',
                                field: 'monitor',
                                sorting: false,
                                cellStyle: {
                                    width: '50px',
                                    textAlign: 'center',
                                    padding: '0px'
                                },
                                headerStyle: {
                                    textAlign: 'center',
                                    padding: '0px'
                                },
                                render: rowData => this.renderMonitor(rowData)
                            },
                            {
                                title: 'Centro',
                                field: 'centro',
                                cellStyle: {
                                    width: '100px',
                                    padding: '0px',
                                    textAlign: 'center'
                                },
                                headerStyle: {
                                    textAlign: 'center',
                                    padding: '0px'
                                }
                            },
                            {
                                title: 'Seguimiento',
                                field: 'num_seguimiento',
                                cellStyle: {
                                    width: '110px',
                                    padding: '0px',
                                    textAlign: 'center'
                                },
                                headerStyle: {
                                    textAlign: 'center',
                                    padding: '0px'
                                },
                                render: rowData => this.renderSeguimiento(rowData)
                            },
                            {
                                title: 'Detalle Traslado',
                                field: 'detalle_traslado',
                                cellStyle: {
                                    width: '150px',
                                    padding: '0px',
                                    textAlign: 'center'
                                },
                                headerStyle: {
                                    textAlign: 'center',
                                    padding: '0px'
                                }
                            },
                            {
                                title: 'Detalle Entrega',
                                field: 'detalle_entrega',
                                cellStyle: {
                                    width: '150px',
                                    padding: '0px',
                                    textAlign: 'center'
                                },
                                headerStyle: {
                                    textAlign: 'center',
                                    padding: '0px'
                                },
                                render: rowData => this.renderDetalleEntrega(rowData)
                            },
                            {
                                title: 'Detalle',
                                field: 'detalle',
                                cellStyle: {
                                    width: '300px',
                                    padding: '0px',
                                    textAlign: 'center'
                                },
                                headerStyle: {
                                    textAlign: 'center',
                                    padding: '0px'
                                }
                            },
                            {
                                title: 'Descripción',
                                field: 'descripcion',
                                cellStyle: {
                                    padding: '0px',
                                    textAlign: 'center'
                                },
                                headerStyle: {
                                    padding: '0px',
                                    textAlign: 'center'
                                }
                            },
                            {
                                title: 'Fecha Requerida',
                                field: 'fecha_requerida',
                                cellStyle: {
                                    width: '130px',
                                    textAlign: 'center',
                                    padding: '0px'
                                },
                                headerStyle: {
                                    textAlign: 'center',
                                    padding: '0px'
                                }
                            },
                            // {
                            //     title: 'Acción',
                            //     field: 'accion',
                            //     sorting: false,
                            //     cellStyle: {
                            //         padding: '0px',
                            //         textAlign: 'center'
                            //     },
                            //     headerStyle: {
                            //         padding: '0px',
                            //         textAlign: 'center'
                            //     }
                            // }
                        ]}
                        data={solicitudActual.items.map((x, id) => ({
                            ...x,
                            seleccionable: x.estado === 6 || x.estado === 17 || x.estado === 18,
                            tableData: {
                                checked: this.state.itemsSeleccionados.find(z => z.id === x.id) ? true : false
                            },
                            num_seguimiento: x.traslados?.length > 0 ? x.traslados[0].numero_seguimiento : "-",
                            detalle_traslado: x.traslados?.length > 0 ? x.traslados[0].informacion_traslado : "-",
                            estado_id: x.estado,
                            estado: x.estadoRef.nombre,
                            centro: x.ubicacionLogistica.nombre,
                            detalle: x.detalle,
                            fecha_requerida: moment(x.fecha_requerida).format('DD-MM-YYYY'),
                            // accion:
                            //     [6, 17, 18].indexOf(x.estado) !== -1 &&
                            //     revisarStock && x.deMisCentros ? (
                            //         <BotonMenuItems
                            //             id={x.id}
                            //             estado={x.estado}
                            //             handleEditarItemsModal={this.handleEditarItemsModal}
                            //         />
                            //     ) : (
                            //         x.estadoRef.nombre
                            //     )
                        }))}
                    />
                </Box>

                <Modal
                    open={this.state.open}
                    handleClose={() => { this.setState({ open: false }); }}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    loading={false}>
                    {mensaje.data}
                </Modal>

                <Modal
                    xl={false}
                    fullWidth={true}
                    title="Traslado de recursos"
                    open={this.state.openItemTraslado}
                    handleClose={this.state.loadingTraslado ? () => {} : this.handleClose}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    // loading={this.state.loadingTraslado}
                    >
                    <FormItemTraslado
                        onSubmit={this.handleItemTraslado}
                        formTraslado={formTraslado}
                        loading={this.state.loadingTraslado}
                        // open={this.state.openItemTraslado}
                        // handleClose={items.fetching ? () => {} : this.handleClose}
                        // confirmacion={this.state.confirmacion}
                        // accion={this.state.accion}
                        // loading={items.fetching}
                        // max={this.state.maxItemsEnTraslado}
                        // enTraslado={this.state.textoItemsEnTraslado}
                        // loading={items.fetching}
                    />
                </Modal>

                <FormListoParaEntrega
                    open={this.state.openListoEntrega}
                    handleClose={this.state.loadingListoEntrega ? () => {} : this.handleClose}
                    // confirmacion={this.state.confirmacion}
                    // accion={this.state.accion}
                    loading={this.state.loadingListoEntrega}
                    onSubmit={this.handleListoParaEntregar}
                    // listoEntrega={this.state.textoItemsListoEntrega}
                    // max={this.state.maxItemsListoEntrega}
                    // loading={items.fetching}
                />

                <Modal
                    xl={false}
                    fullWidth={true}
                    title="Entrega de recursos"
                    open={this.state.openModalEntregar}
                    handleClose={this.state.loadingEntrega ? () => {} : this.handleClose}
                    confirmacion={this.state.confirmacion}
                    accion={this.state.accion}
                    loading={this.state.loadingEntrega}>
                    <FormEntregarItems
                        onSubmit={this.handleEntregarItems}
                        // open={this.state.openModalEntregar}
                        // handleClose={items.fetching ? () => {} : this.handleClose}
                        handleOnChangeFileComprobante={this.handleOnChangeFileComprobante}
                        fileName={this.state.comprobante}
                        // entregados={this.state.textoItemsEntregados}
                        // max={this.state.maxItemsEntrega}
                        // loading={items.fetching}
                    />
                </Modal>
            </Grid>
        );
    }
}

const mapStateToProps = state => {
    return {
        usuarioAutenticado: state.Usuario,
        mensaje: state.Mensajes,
        form: state.form,
        formTraslado: state.form.traslado,
        revisarStock: state.PermisosUsuario.data.some(x => x.nombre === 'revisarStock'),
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            ...solicitudesUsuarioDuck,
            ...solicitudesAprobarDuck,
            ...solicitudesDuck,
            ...idsolicitudDuck,
            ...iditemDuck,
            ...itemsDuck,
            ...aprobador1Duck,
            ...aprobador2Duck,
            ...vehiculoDuck,
            ...mensajesDuck,
            ...modoEdicionDuck,
            ...gruposCotizacionDuck,
            ...cotizacionesDuck,
            ...idGrupoCotizacionDuck,
            ...itemsCotizacionDuck,
            ...archivoDuck,
            ...itemsCotizadosDuck,
            ...proyectosGeneralDuck,
            ...gerenciasGeneralDuck,
            ...comprobanteRecepcionDuck,
            ...entregaItemRecurso,
            ...trasladoItemRecursoDuck
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(EntregaRecepcion);
